const TEST_FOLDER_1_ID = "1FTUnmFtODFAbaFNx7Ftd36fU18kE5j5d";
const TEST_FOLDER_2_ID = "1rB0tvcl75G4HjRe-7WQMun32CkUtSUZL";

const FOLDER_MIME = "application/vnd.google-apps.folder"

export default async function pickFromDrive({
  access_token,
  clientId,
  apiKey,
  mimeType = ''
}) {
  if (typeof option === 'string')
    options = { type: options }
  console.log('opening picker')
  return new Promise((res, rej) => {
    // return null

    const myDriveView = new google.picker.DocsView()
    myDriveView.setParent("root")
    myDriveView.setIncludeFolders(true)
    myDriveView.setSelectFolderEnabled(true)
    // myDriveView.setMimeTypes(FOLDER_MIME)
    myDriveView.setOwnedByMe(true)
    myDriveView.setMode(google.picker.DocsViewMode.LIST)

    const sharedDriveView = new google.picker.DocsView();
    sharedDriveView.setIncludeFolders(true);
    sharedDriveView.setSelectFolderEnabled(true);
    // sharedDriveView.setMimeTypes(FOLDER_MIME);
    sharedDriveView.setEnableDrives(true)
    sharedDriveView.setMode(google.picker.DocsViewMode.LIST)

    var picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
      .addView(myDriveView)
      .addView(sharedDriveView)
      // .setSize(1051, 2000)
      .setTitle('Pick a folder')
      // .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setOAuthToken(access_token)
      .setAppId(clientId)
      .setDeveloperKey(apiKey)
      .setCallback(event => {
        if (event.action == google.picker.Action.PICKED) {

          let doc = event.docs[0]
          if (!doc)
            rej(`no file picked`)
          if (mimeType) {
            switch (options.type) {
              case 'folder':
                mimeType = FOLDER_MIME
                break;
              default:
                break;
            }
            if (doc.mimeType !== mimeType)
              rej(`a ${mimeType} is expected but a ${doc.mimeType} was returned`)
          }
          res(doc);
        }
      })
      .build();
    picker.setVisible(true);
  });
}
[
  {
    "id": "1QDjEo2IlfzVf0IX1Z_aV7Sq39TdFBz2e",
    "serviceId": "docs",
    "mimeType": "application/vnd.google-apps.folder",
    "name": "Paperasse",
    "description": "",
    "type": "folder",
    "lastEditedUtc": 1564654777892,
    "iconUrl": "https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.folder",
    "url": "https://drive.google.com/drive/folders/1QDjEo2IlfzVf0IX1Z_aV7Sq39TdFBz2e",
    "embedUrl": "https://drive.google.com/embeddedfolderview?id=1QDjEo2IlfzVf0IX1Z_aV7Sq39TdFBz2e",
    "sizeBytes": 0,
    "parentId": "root"
  }
]

