function log() {
    console.log(`${PROTOCOL}: `, ...arguments)
}

function isBlob(data) {
    if (data instanceof Blob || data.toString() === "[object Blob]") {
        return true
    }
}

const CONNECTION_TIMEOUT = 3000;
const KEEPALIVE_PERIOD = 1000;
const ITC_VERSION = 6
const PROTOCOL = `itc-v${ITC_VERSION}`
const PRESENTATION_MESSAGE = {
    protocol: PROTOCOL
}




const defaultOpenConfig = {
    features: "_blank",
}

// can not check window.opener because it is null cross-domain
const url = new URL(window.location)
const getParams = url.searchParams
const protocol = getParams.get('protocol')
const CAN_HANDLE = (protocol === "" || protocol === PROTOCOL)

function getParameters(str) {
    const url = new URL(str)
    if (!url.searchParams.has('protocol'))
        url.searchParams.set('protocol', PROTOCOL)
    let timeout = url.searchParams.get('timeout') || 3000
    timeout = Number(timeout)
    let relay = url.searchParams.get('relay')
    let simplex = url.searchParams.has('simplex')
    const autoclose = url.searchParams.has('autoclose')
    const channel = url.searchParams.get('channel')

    return { ...PRESENTATION_MESSAGE, timeout, simplex, autoclose, relay, channel }
}

class ItcChannel extends EventTarget {
    constructor(tx, rx, config) {
        super()
        this.config = config
        this.tx = tx
        this.rx = rx
        this.name = config.name
        this.connected = true
        log(`ItcChannel creation ${JSON.stringify(config)}`)
        const notifyDisconnect = () => {
            this.connected = false
            log('disconnected (lost ping)')
            const evt = new Event('disconnected')
            this.dispatchEvent(evt)
        }

        const alive = () => {
            if (this.aliveTimeout)
                clearTimeout(this.aliveTimeout)
            this.aliveTimeout = setTimeout(() => {
                notifyDisconnect()
                if (this.config.autoclose)
                    window.close()
            }, KEEPALIVE_PERIOD + 500)
        }
        const ignoreMessage = (evt) => {
            return evt.source !== window && (
                !evt.data ||
                typeof evt.data === 'object' && (evt.data.protocol || "").startsWith('itc-')
            )
        }

        this.messageHandler = e => {
            // should check for domain
            if (e.source !== window && (this.config.role === 'slave') || (this.config.role === 'master')) {
                alive()
            }
            if (ignoreMessage(e)) return

            log('rx :', JSON.stringify(e.data))

            const event = new Event('message')
            event.data = e.data
            event.origin = e.origin
            this.dispatchEvent(event)
        }
        if (this.rx) {
            this.rx.addEventListener('message', this.messageHandler)
            alive();
        }
        if (this.tx) {
            const { channel } = config
            const sendPing = () => {
                const pingMessage = { protocol: PROTOCOL, channel }
                this.tx.postMessage(pingMessage, "*")
                if (!this.cleaned)
                    this.pingTimeout = setTimeout(sendPing, KEEPALIVE_PERIOD)
            }
            sendPing()
        }

    }
    send(data) {
        if (!this.tx) {
            throw 'this channel is half-duplex : "send" not possible'
        }
        log('tx :', JSON.stringify(data))
        this.tx.postMessage(data, "*")

    }
    clean() {
        if (this.aliveTimeout)
            clearTimeout(this.aliveTimeout)
        if (this.pingTimeout)
            clearTimeout(this.pingTimeout)
        if (this.rx) this.rx.removeEventListener('message', this.messageHandler)
        this.cleaned = true
    }
}













const sleep = (ms) => new Promise((res, rej) => setTimeout(res, ms))
export async function open(initialUrl, configOverrides) {
    let connected = false
    const config = { ...defaultOpenConfig, ...configOverrides }
    const { name, features, timeout, autoclose, relay, simplex } = config
    return new Promise((res, rej) => {
        if (timeout)
            setTimeout(() => {
                if (!connected) {
                    rej(`no handshake after ${timeout}ms : the opened app does not implement itc protocol v${ITC_VERSION}`)
                }
            }, timeout)
        try {
            let role = 'master'
            let presentation

            if (!relay) {
                rej('a relay in your domain must be provided')
                return;
            }
            if (!name) {
                rej('a name for the window must be provided')
                return;
            }
            const channel = `${name}-${Date.now()}`
            const url = new URL(initialUrl)
            url.searchParams.set('relay', relay) // should throw or be infered
            url.searchParams.set('channel', channel)


            presentation = { ...PRESENTATION_MESSAGE, role, name, timeout, channel }

            url.searchParams.set('protocol', PROTOCOL)

            if (autoclose) url.searchParams.set('autoclose', '')
            if (Number.isFinite(timeout)) url.searchParams.set('timeout', timeout)

            log('opening window', url.toString())
            const slave = window.open(url.toString(), name, features)
            window._slave = slave

            let rx = null
            const resolve = (other = {}) => {
                connected = true
                log(`CONNECTED \nto ${JSON.stringify(other)} \nas ${JSON.stringify(presentation)}`)
                res(new ItcChannel(slave, rx, presentation))
            }

            if (simplex) {
                resolve()
            } else {
                log('listening to broadcast channel', channel)
                const broadcastChannel = new BroadcastChannel(channel)
                rx = broadcastChannel

                const preConnectionMessageHandler = (m) => {
                    if (m.source !== window) log('broadcast>', JSON.stringify(m.data))
                    if (!connected && m.data) {
                        if (m.data.protocol === PROTOCOL && m.data.role === 'slave') {
                            log("received slave presentation", m.data)
                            resolve(m.data)
                            broadcastChannel.removeEventListener('message', preConnectionMessageHandler)
                        } else if (m.data.protocol === PROTOCOL && m.data.role === 'relay') {
                            log("received relay presentation", m.data)
                            log("sending presentation to slave", presentation)
                            slave.postMessage(presentation, "*")
                        } else {
                            const error = `invalid presentation : ${JSON.stringify(m.data)}`
                            log(error)
                            broadcastChannel.removeEventListener('message', preConnectionMessageHandler)
                            rej(error)
                            return
                        }
                    }
                }
                broadcastChannel.addEventListener('message', preConnectionMessageHandler)

            }
        } catch (err) {
            rej(err)
        }
    })
}












export async function handle(configOverrides = {}) {
    let connected = false
    return new Promise((res, rej) => {

        if (!CAN_HANDLE) {
            rej(`itc impossible for this window. \nThis app supports ${PROTOCOL}.\nMake sure it is open with 'protocol' get param properly set to ${PROTOCOL}\nCurrent param is ${protocol}`)
            return;
        }
        const parameters = { ...getParameters(window.location.href), ...configOverrides }
        const { timeout, autoclose, relay, channel, simplex } = parameters
        log("itc slave", parameters)
        if (!relay) {
            return rej(`a 'relay' url must be provided`)
        }
        log("opening itc relay", relay)
        const relayUrl = new URL(relay)
        relayUrl.searchParams.set('channel', channel)
        const iframe = document.createElement('iframe');
        iframe.style.width = '100%';
        iframe.style.display = "none";
        iframe.src = relayUrl.toString();
        document.body.appendChild(iframe);

        let role = 'slave'
        let presentation = { ...parameters, role, name: window.name, channel }



        const resolve = (masterPresentation = {}) => {
            log(`CONNECTED \nto ${JSON.stringify(masterPresentation)} \nas ${JSON.stringify(presentation)}`)
            connected = true
            log("sending presentation to relay", JSON.stringify(presentation))
            iframe.contentWindow.postMessage(presentation, "*")
            let channel = new ItcChannel(iframe.contentWindow, window, presentation)
            res(channel)
        }
        window.addEventListener("message", messageHandler)
        function messageHandler(e) {
            if (e.source !== window) log(`${e.origin}>window>`, JSON.stringify(e.data))
            if (e.source !== window) {
                if (!connected && e.data && e.data.protocol === PROTOCOL && e.data.role === 'master') {
                    log("received presentation from master", e.data)
                    window.removeEventListener('message', messageHandler)
                    resolve(e.data)
                }
            }
        }


        if (timeout)
            setTimeout(() => {
                if (!connected) {
                    if (autoclose)
                        window.close()
                    rej(`no handshake after ${timeout}ms : the opening app does not implement itc protocol v${ITC_VERSION}`)
                }
            }, timeout)
    })
}