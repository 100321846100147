
export function renderSize(n = 0) {
    let suffix = 'B'
    if (n > 1000) {
        n /= 1024
        suffix = 'KB'
    }
    if (n > 1000) {
        n /= 1024
        suffix = 'MB'
    }
    if (n > 1000) {
        n /= 1024
        suffix = 'GB'
    }
    return n.toFixed(1) + suffix
}