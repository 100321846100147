import {svg} from 'lit'
export const useGDrive = svg`<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 207.027 207.027" style="enable-background:new 0 0 207.027 207.027;" xml:space="preserve"> <path fill="currentColor" d="M69.866,15.557L0,138.919l28.732,52.552l143.288-0.029l35.008-59.588L136.39,15.735L69.866,15.557z M17.166,139.046 	L74.268,38.205L91.21,67.783L33.24,168.447L17.166,139.046z M99.841,82.851l23.805,41.558l-47.732-0.006L99.841,82.851z 	 M163.434,176.443l-117.332,0.024l21.53-37.065l64.606,0.008l0.067,0.119l52.865-0.085L163.434,176.443z M140.932,124.411 	L90.157,35.767l-2.966-5.178l40.751,0.121l57.003,93.706L140.932,124.411z"/> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>`

export const addGDrive = svg`<svg
xmlns:dc="http://purl.org/dc/elements/1.1/"
xmlns:cc="http://creativecommons.org/ns#"
xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
xmlns:svg="http://www.w3.org/2000/svg"
xmlns="http://www.w3.org/2000/svg"
xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
version="1.1"
id="Capa_1"
x="0px"
y="0px"
viewBox="0 0 211.38088 186.46302"
xml:space="preserve"
sodipodi:docname="add-google-drive.svg"
width="211.38087"
height="186.46301"
inkscape:version="0.92.5 (2060ec1f9f, 2020-04-08)"><metadata
id="metadata945"><rdf:RDF><cc:Work
    rdf:about=""><dc:format>image/svg+xml</dc:format><dc:type
      rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title></dc:title></cc:Work></rdf:RDF></metadata><defs
id="defs943" /><sodipodi:namedview
pagecolor="#ffffff"
bordercolor="#666666"
borderopacity="1"
objecttolerance="10"
gridtolerance="10"
guidetolerance="10"
inkscape:pageopacity="0"
inkscape:pageshadow="2"
inkscape:window-width="1916"
inkscape:window-height="1161"
id="namedview941"
showgrid="false"
fit-margin-top="0"
fit-margin-left="0"
fit-margin-right="0"
fit-margin-bottom="0"
inkscape:zoom="4.5936039"
inkscape:cx="103.5135"
inkscape:cy="98.506523"
inkscape:window-x="0"
inkscape:window-y="37"
inkscape:window-maximized="0"
inkscape:current-layer="Capa_1" /> <path
fill="currentColor"
d="M 69.866,0 0,123.362 28.732,175.914 172.02,175.885 207.028,116.297 136.39,0.178 Z M 17.166,123.489 74.268,22.648 91.21,52.226 33.24,152.89 Z m 82.675,-56.195 23.805,41.558 -47.732,-0.006 z m 63.593,93.592 -117.332,0.024 21.53,-37.065 64.606,0.008 0.067,0.119 52.865,-0.085 z M 140.932,108.854 90.157,20.21 l -2.966,-5.178 40.751,0.121 57.003,93.706 z"
id="path908"
inkscape:connector-curvature="0" /> <g
id="g910"
transform="translate(0,-15.557)"> </g> <g
id="g912"
transform="translate(0,-15.557)"> </g> <g
id="g914"
transform="translate(0,-15.557)"> </g> <g
id="g916"
transform="translate(0,-15.557)"> </g> <g
id="g918"
transform="translate(0,-15.557)"> </g> <g
id="g920"
transform="translate(0,-15.557)"> </g> <g
id="g922"
transform="translate(0,-15.557)"> </g> <g
id="g924"
transform="translate(0,-15.557)"> </g> <g
id="g926"
transform="translate(0,-15.557)"> </g> <g
id="g928"
transform="translate(0,-15.557)"> </g> <g
id="g930"
transform="translate(0,-15.557)"> </g> <g
id="g932"
transform="translate(0,-15.557)"> </g> <g
id="g934"
transform="translate(0,-15.557)"> </g> <g
id="g936"
transform="translate(0,-15.557)"> </g> <g
id="g938"
transform="translate(0,-15.557)"> </g> <circle
style="fill:#ffffff;stroke:none;stroke-width:1.00157475;stroke-miterlimit:4;stroke-dasharray:none"
id="path947"
cx="148.79385"
cy="123.876"
r="62.587021" /><path
style="fill:none;stroke:#000000;stroke-width:10.01574802;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
d="M 148.79385,80.010659 V 167.74134"
id="path949"
inkscape:connector-curvature="0" /><path
style="fill:none;stroke:#000000;stroke-width:10.01574802;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
d="M 192.65919,123.876 H 104.92851"
id="path949-3"
inkscape:connector-curvature="0" /></svg>`